<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form v-slot="{ errors }" @submit="handleFormSubmit" ref="newsletter-setting-form" class="newsletter-setting-form">
                    <div class="content_area">
                        <div class="info_bar">
                            <div class="time">Estimated Time: <span>6 min.</span></div>
                            <div class="step_bar2">
                                <ul>
                                    <li :class="{ active : step == 0 || step > 0 }">
                                        <span @click="form.is_default == 0 ? step = 0 : ''">1</span>
                                        <h6>General Setup</h6>
                                    </li>
                                    <li :class="{ active : step == 1 || step > 0 }">
                                        <span @click="form.is_default == 0 ? step = 1 : ''">2</span>
                                        <h6>Popup Setup</h6>
                                    </li>
                                    <li :class="{ active : step == 2 || step > 1 }">
                                        <span @click="form.is_default == 0 ? step = 2 : ''">3</span>
                                        <h6>Thank You</h6>
                                    </li>
                                    <li :class="{ active : step == 3 || step > 2 }">
                                        <span @click="form.is_default == 0 ? step = 3 : ''">4</span>
                                        <h6>Contact Notification</h6>
                                    </li>
                                    <li :class="{ active : step == 4 || step > 3 }">
                                        <span @click="form.is_default == 0 ? step = 4 : ''">5</span>
                                        <h6>Admin Notification</h6>
                                    </li>
                                    <li :class="{ active : step == 5 || step > 4 }">
                                        <span @click="form.is_default == 0 ? step = 5 : ''">6</span>
                                        <h6>Seo Setup</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 5) * step }%`"></span></div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 0">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Name</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Name</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" rules="required" />
                                                </div>
                                                <ErrorMessage name="name" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Newsletter Cover</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div>
                                            <h3 class="sub_header">Background Image <button type="button" class="add_btn" @click="coverCropper = true">Cropper</button></h3>
                                            <image-library v-model="form.cover.main_img" :open-external-cropper="openNewsletterCoverCropper" :crop="false" image-type="newsletter-cover" upload-text="Image" />
                                            <div class="canva-flex">
                                                <a class="reset-default mtn-30" @click="resetToDefault('cover', 'main_img')">Reset to default</a>
                                                <a class="canva-template mtn-30 mr-4" href="https://www.canva.com/design/DAFWtrelSEw/A-L1Fp9inRXrd2eqrR11jQ/view?utm_content=DAFWtrelSEw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank">Canva Template</a>
                                            </div>
                                        </div>
                                        <label for="overlay" class="switch_option capsule_btn">
                                            <h5 class="large">Add Image Overlay</h5>
                                            <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.cover.has_overlay" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.cover.has_overlay">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Overlay Color <a class="reset-default" @click="resetToDefault('cover', 'overlay_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="overlay_color" v-model="form.cover.overlay_color" type="text" label="overlay color">
                                                        <color-picker v-model="form.cover.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                    </Field>
                                                    <ErrorMessage name="overlay_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Overlay Opacity <a class="reset-default" @click="resetToDefault('cover', 'overlay_opacity')">Reset to default</a></label>
                                                    <div class="field_wpr">
                                                        <Field autocomplete="nofill" name="overlay_opacity" v-model="form.cover.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="overlay opacity" />
                                                    </div>
                                                    <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Opt-In Call Out</h2>
                                    <label for="callout" class="switch_option capsule_btn">
                                        <input type="checkbox" id="callout" :true-value="1" :false-value="0" v-model="form.call_out.has_call_out" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Text
                                                    <div class="flex-center">
                                                        <button type="button" class="reset-default" @click="resetToDefault('call_out', 'text')">Reset to default</button>
                                                        <button type="button" class="px-1" @click="calloutSetting = !calloutSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                    </div>
                                                </label>
                                                <redactor name="call_out_text" v-model="form.call_out.text" :default-value="newsletterDefaultValues.call_out" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                            </div>
                                        </div>
                                        <div v-show="calloutSetting">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Color <a class="reset-default" @click="resetToDefault('call_out', 'text_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="font_color" v-model="form.call_out.text_color" type="text" label="element color">
                                                        <color-picker v-model="form.call_out.text_color" :classes="{ 'has-error': errors.font_color }" />
                                                    </Field>
                                                    <ErrorMessage name="font_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Opt-in Headline</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Text
                                                    <div class="flex-center">
                                                        <button type="button" class="reset-default" @click="resetToDefault('headline', 'text')">Reset to default</button>
                                                        <button type="button" class="px-1" @click="showHeadlineSetting = !showHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                    </div>
                                                </label>
                                                <redactor name="call_headline_text" v-model="form.headline.text" :default-value="newsletterDefaultValues.headline" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                            </div>
                                        </div>
                                        <div v-show="showHeadlineSetting">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Color <a class="reset-default" @click="resetToDefault('headline', 'text_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="font_color" v-model="form.headline.text_color" type="text" label="element color">
                                                        <color-picker v-model="form.headline.text_color" :classes="{ 'has-error': errors.font_color }" />
                                                    </Field>
                                                    <ErrorMessage name="font_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <label for="headline_shadow" class="switch_option capsule_btn">
                                                    <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                    <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline.has_shadow" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.headline.has_shadow">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Offset-x</label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="nofill" type="number" name="headline_shadow_x" v-model="form.headline.shadow_x" min="0" max="100" placeholder="4" />
                                                            </div>
                                                        </div>
                                                        <div class="group_item">
                                                            <label class="input_label">Offset-y</label>
                                                            <div class="field_wpr ">
                                                                <Field autocomplete="nofill" type="number" name="headline_shadow_y" v-model="form.headline.shadow_y" min="0" max="100" placeholder="4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-3">
                                                        <div class="group_item">
                                                            <label class="input_label">Blur-Radius</label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="nofill" type="number" name="headline_shadow_blur" v-model="form.headline.shadow_blur" min="0" max="100" placeholder="68" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Shadow Color</label>
                                                            <Field autocomplete="off" name="headline_shadow_color" v-model="form.headline.shadow_color" type="text" label="element color">
                                                                <color-picker v-model="form.headline.shadow_color" :classes="{ 'has-error': errors.headline_shadow_color }" />
                                                            </Field>
                                                            <ErrorMessage name="headline_shadow_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Opt-in Sub Headline</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Text
                                                    <div class="flex-center">
                                                        <button type="button" class="reset-default" @click="resetToDefault('sub_headline', 'text')">Reset to default</button>
                                                        <button type="button" class="px-1" @click="showSubHeadlineSetting = !showSubHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                    </div>
                                                </label>
                                                <redactor name="sub_headline_text" v-model="form.sub_headline.text" :default-value="newsletterDefaultValues.sub_headline" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                            </div>
                                        </div>
                                        <div v-show="showSubHeadlineSetting">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Color <a class="reset-default" @click="resetToDefault('sub_headline', 'text_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.sub_headline.text_color" type="text" label="element color">
                                                        <color-picker v-model="form.sub_headline.text_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                    </Field>
                                                    <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.has_benefit" class="section_content w-100">
                                <div class="section_header">
                                    <h2>Benefits</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp" v-for="(benefit, b) in form.benefit.benefits" :key="b">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Benefit-{{(b + 1)}}

                                                    <div class="flex-center">
                                                        <button type="button" class="reset-default mr-1" @click="resetToDefault('benefit', 'benefits', b)">
                                                            Reset to default
                                                        </button>
                                                        <button type="button" class="danger pointer" @click="handleDeleteBenefit(b)"><i class="fas fa-trash-alt"></i></button>
                                                    </div>
                                                </label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" :name="`benefit_title-${b}`" v-model="form.benefit.benefits[b]"/>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" v-if="(form.benefit.benefits.length < 3)" class="add_btn ml-auto mr-0" @click="addBenefit"><i class="fas fa-plus"></i>Add New Feature</button>
                                        <div class="form_grp mt-3">
                                            <div class="group_item">
                                                <label class="input_label">Text Color <a class="reset-default" @click="resetToDefault('benefit', 'text_color')">Reset to default</a></label>
                                                <Field autocomplete="off" name="text_color" v-model="form.benefit.text_color" type="text" label="element color">
                                                    <color-picker v-model="form.benefit.text_color" :classes="{ 'has-error': errors.text_color }" />
                                                </Field>
                                                <ErrorMessage name="text_color" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Icon Color <a class="reset-default" @click="resetToDefault('benefit', 'icon_color')">Reset to default</a></label>
                                                <Field autocomplete="off" name="icon_color" v-model="form.benefit.icon_color" type="text" label="element color">
                                                    <color-picker v-model="form.benefit.icon_color" :classes="{ 'has-error': errors.icon_color }" />
                                                </Field>
                                                <ErrorMessage name="icon_color" class="text-danger" />
                                            </div>
                                        </div>
                                        <h3 class="sub_header mt-2">Icons</h3>
                                        <div class="icon_section mb-3">
                                            <ul>
                                                <li v-for="(icon, i) in icons" :key="i">
                                                    <label :for="`icon-${i}`" :class="{'active' : icon == form.benefit.icon}">
                                                        <input type="radio" name="select_icon" :id="`icon-${i}`" :value="icon" v-model="form.benefit.icon" hidden>
                                                        <img :src="require(`@/assets/images/${icon}.svg`)" alt="">
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Sign-Up Button</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Text <a class="reset-default" @click="resetToDefault('cta_button', 'text')">Reset to default</a></label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="text" name="action_btn" v-model="form.cta_button.text" placeholder="Sign Up" />
                                                </div>
                                                <ErrorMessage name="button_text" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Color <a class="reset-default" @click="resetToDefault('cta_button', 'bg_color')">Reset to default</a></label>
                                                <Field autocomplete="off" name="button_clr" v-model="form.cta_button.bg_color" type="text" label="element color">
                                                    <color-picker v-model="form.cta_button.bg_color" :classes="{ 'has-error': errors.button_clr }" />
                                                </Field>
                                                <ErrorMessage name="button_clr" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Button Text Color <a class="reset-default" @click="resetToDefault('cta_button', 'text_color')">Reset to default</a></label>
                                                <Field autocomplete="off" name="button_text_clr" v-model="form.cta_button.text_color" type="text" label="element color">
                                                    <color-picker v-model="form.cta_button.text_color" :classes="{ 'has-error': errors.text_clr }" />
                                                </Field>
                                                <ErrorMessage name="button_text_clr" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Coach</h2>
                                    <label for="coach_img" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.coach.has_coach" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-1" v-show="form.coach.has_coach">
                                    <h3 class="sub_header mt-2">Coach Image</h3>
                                    <image-library v-model="form.coach.image" image-type="coach-logo" upload-text="Image" />
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Coach Name</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="coach_name" v-model="form.coach.name" placeholder="Coach name goes here.."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Coach Credentials</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="coach_credential" v-model="form.coach.credentials" placeholder="Coach credentials goes here.."/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label for="coach_top" class="switch_option capsule_btn pb-4 ml-0">
                                        <h5>Top Right Align to Desktop Top Right</h5>
                                        <input type="radio" id="coach_top" value="top-right" v-model="form.coach.align" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <label for="coach_bottom" class="switch_option capsule_btn">
                                        <h5>Bottom Right Align to Desktop Bottom Right</h5>
                                        <input type="radio" id="coach_bottom" value="bottom-right" v-model="form.coach.align" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Branding</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Copyright Text <a class="reset-default" @click="resetToDefault('copyright', 'text')">Reset to default</a></label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="copyright" v-model="form.copyright.text"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Text Color <a class="reset-default" @click="resetToDefault('copyright', 'text_color')">Reset to default</a></label>
                                                <Field autocomplete="off" name="copyright_color" v-model="form.copyright.text_color" type="text" label="element color">
                                                    <color-picker v-model="form.copyright.text_color" :classes="{ 'has-error': errors.copyright_color }" />
                                                </Field>
                                                <ErrorMessage name="copyright_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tags</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Select Tags
                                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                                </label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                    <Field autocomplete="off" name="tag" v-model="form.tags">
                                                        <multiselect
                                                            v-model="form.tags"
                                                            mode="tags"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :group-select="true"
                                                            :options="tagsGroup"
                                                            :groups="true"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            placeholder="Select Tags"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="tag" class="text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="createTags" class="form_grp">
                                            <Form v-slot="{ errors }" ref="tag-form">
                                                <div class="group_item">
                                                    <label class="input_label">Create a New Tag</label>
                                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                    </div>
                                                    <ErrorMessage name="name" class="text-danger" />
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tab</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select segment</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.tabs }">
                                                    <Field autocomplete="off" name="tabs" v-model="form.tab" rules="required">
                                                        <multiselect
                                                            v-model="form.tab"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="statusesGroup"
                                                            valueProp="value"
                                                            label="title"
                                                            placeholder="Select segment"
                                                            :group-select="false"
                                                            group-label="type"
                                                            group-options="items"
                                                            :group-hide-empty="true"
                                                            open-direction="top"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span class="status-tabs-header">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.title }}</span>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="tabs" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 1">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Pop-Up Section</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <h3 class="sub_header">Opt-In Image <button type="button" class="add_btn" @click="popupCropper = true">Cropper</button></h3>
                                    <image-library v-model="form.popup.main_img" :open-external-cropper="openNewsletterPopupCropper" :crop="false" image-type="newsletter-popup" upload-text="Image" />
                                    <a class="reset-default mtn-30" @click="resetToDefault('popup', 'main_img')">Reset to default</a>
                                    <h3 class="sub_header mt-5">Headline</h3>
                                    <div class="edit_section">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">
                                                        Text
                                                        <div class="flex-center">
                                                            <button type="button" class="reset-default" @click="resetToDefault('popup', 'headline_text')">Reset to default</button>
                                                            <button type="button" class="px-1" @click="showHeadlineSetting = !showHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                        </div>
                                                    </label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.text }">
                                                        <Field autocomplete="off" type="text" name="headline" v-model="form.popup.headline_text" />
                                                    </div>
                                                    <ErrorMessage name="headline" class="text-danger" />
                                                </div>
                                            </div>
                                            <div v-show="showHeadlineSetting">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Font-Family <a class="reset-default" @click="resetToDefault('popup', 'headline_font_family')">Reset to default</a></label>
                                                        <div class="field_wpr m-0">
                                                            <multiselect v-model="form.popup.headline_font_family" v-bind="fontFamily"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <label class="input_label">Font-Weight <a class="reset-default" @click="resetToDefault('popup', 'headline_font_weight')">Reset to default</a></label>
                                                        <div class="field_wpr m-0">
                                                            <multiselect v-model="form.popup.headline_font_weight" v-bind="fontWeight"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Color <a class="reset-default" @click="resetToDefault('popup', 'headline_text_color')">Reset to default</a></label>
                                                        <Field autocomplete="off" name="cover_textcolor" v-model="form.popup.headline_text_color" type="text" label="element color">
                                                            <color-picker v-model="form.popup.headline_text_color" :classes="{ 'has-error': errors.font_color }" />
                                                        </Field>
                                                        <ErrorMessage name="cover_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-5">Opt-In Fields</h3>
                                    <div class="edit_section">
                                        <ul class="optin_list">
                                            <li>
                                                <label for="optin-fname" class="switch_option capsule_btn">
                                                    <h5 class="large">First Name</h5>
                                                    <input type="checkbox" id="optin-fname" :true-value="1" :false-value="0" v-model="form.optin_fields.first_name" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="optin-lname" class="switch_option capsule_btn">
                                                    <h5 class="large">Last Name</h5>
                                                    <input type="checkbox" id="optin-lname" :true-value="1" :false-value="0" v-model="form.optin_fields.last_name" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="optin-email" class="switch_option capsule_btn">
                                                    <h5 class="large">Email</h5>
                                                    <input type="checkbox" id="optin-email" :true-value="1" :false-value="0" v-model="form.optin_fields.email" hidden :disabled="true">
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="optin-phone" class="switch_option capsule_btn">
                                                    <h5 class="large">Phone</h5>
                                                    <input type="checkbox" id="optin-phone" :true-value="1" :false-value="0" v-model="form.optin_fields.phone" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="sub_header mt-5">
                                        GDPR Permission
                                        <label for="gdpr" class="switch_option capsule_btn p-0">
                                            <input type="checkbox" id="gdpr" :true-value="1" :false-value="0" v-model="form.has_gdpr" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </h3>
                                    <div class="edit_section" v-if="form.has_gdpr">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label" for="gdpr-permission">
                                                        Text
                                                        <div class="flex-center">
                                                            <button type="button" class="reset-default mr-1" @click="resetToDefault('gdpr_permission')">Reset to default</button>
                                                            <div class="dropdown mr-1" @click="gdprPersonalizeOption = !gdprPersonalizeOption">
                                                                <i class="fas fa-user-alt"></i>
                                                                <div class="drp_wrapper" v-if="gdprPersonalizeOption" :class="{ active : gdprPersonalizeOption }">
                                                                    <ul>
                                                                        <li v-for="(personalize, p) of defaultPersonalize" :key="p" @click="insertPersonalize(personalize)">{{ personalize }}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="gdpr" id="gdpr-permission"  v-model="form.gdpr_permission"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-5">Submit Button</h3>
                                    <div class="edit_section">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text <a class="reset-default" @click="resetToDefault('optin_submit_button', 'action_button_text')">Reset to default</a></label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="popup_action_btn" v-model="form.optin_submit_button.action_button_text" placeholder="Sign Up" />
                                                    </div>
                                                    <ErrorMessage name="popup_action_btn" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color <a class="reset-default" @click="resetToDefault('optin_submit_button', 'action_button_bg_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="popup_button_clr" v-model="form.optin_submit_button.action_button_bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.optin_submit_button.action_button_bg_color" :classes="{ 'has-error': errors.btn_clr }" />
                                                    </Field>
                                                    <ErrorMessage name="popup_button_clr" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color <a class="reset-default" @click="resetToDefault('optin_submit_button', 'action_button_text_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="popup_button_text_clr" v-model="form.optin_submit_button.action_button_text_color" type="text" label="element color">
                                                        <color-picker v-model="form.optin_submit_button.action_button_text_color" :classes="{ 'has-error': errors.text_clr }" />
                                                    </Field>
                                                    <ErrorMessage name="popup_button_text_clr" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-5">
                                        Privacy Statement
                                        <label for="security" class="switch_option capsule_btn p-0">
                                            <input type="checkbox" id="security" :true-value="1" :false-value="0" v-model="form.privacy.has_privacy" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </h3>
                                    <div class="edit_section">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text <a class="reset-default" @click="resetToDefault('privacy', 'text')">Reset to default</a></label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="security" v-model="form.privacy.text"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Icon Color <a class="reset-default" @click="resetToDefault('privacy', 'icon_color')">Reset to default</a></label>
                                                    <Field autocomplete="off" name="icon_color" v-model="form.privacy.icon_color" type="text" label="element color">
                                                        <color-picker v-model="form.privacy.icon_color" :classes="{ 'has-error': errors.icon_color }" />
                                                    </Field>
                                                    <ErrorMessage name="icon_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 2">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Thank You Page Settings</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="border-bottom pb-2 mb-2">
                                            <label for="redirect" class="switch_option capsule_btn m-0 border-0">
                                                <h5>Redirect URL<span>Redirect contact to a specific URL.</span></h5>
                                                <input type="radio" id="redirect" value="1" v-model="form.thankyou_type" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp" v-if="form.thankyou_type == 1">
                                                <div class="group_item">
                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.thank_redirect }">
                                                        <Field autocomplete="nofill" type="text" name="thank_redirect" v-model="form.thankyou_settings.redirect_url" placeholder="https://superfitcoaching.com" rules="required|url_bar" label="redirect URL" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="thank_redirect" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <label for="template" class="switch_option capsule_btn">
                                            <h5>Thank You Page<span>Send contact a thank you template.</span></h5>
                                            <input type="radio" id="template" value="2" v-model="form.thankyou_type" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.thankyou_type == 2">
                                            <div class="form_grp p-0 mt-2">
                                                <div class="group_item">
                                                    <label class="input_label">Public URL</label>
                                                    <div class="field_wpr has_suffix">
                                                        <Field type="text" name="url" v-model="form.thankyou_public_url" readonly/>
                                                        <span class="suffix pointer" @click="copyLink">Copy</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 class="sub_header mt-5">Thank You Cover</h3>
                                            <div class="edit_section pt-3">
                                                <h3 class="sub_header mt-2">Background Image <button type="button" class="add_btn" @click="thankyouCropper = true">Cropper</button></h3>
                                                <image-library v-model="form.thankyou_cover.main_img" :open-external-cropper="openNewsletterThankyouCoverCropper" :crop="false" image-type="newsletter-thankyou-cover" upload-text="Image" />
                                                <a class="reset-default mtn-30" @click="resetToDefault('thankyou_cover', 'main_img')">Reset to default</a>
                                                <label for="overlay" class="switch_option capsule_btn">
                                                    <h5 class="large">Add Image Overlay</h5>
                                                    <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.thankyou_cover.has_overlay" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.thankyou_cover.has_overlay">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Overlay Color <a class="reset-default" @click="resetToDefault('thankyou_cover', 'overlay_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="overlay_color" v-model="form.thankyou_cover.overlay_color" type="text" label="overlay color">
                                                                <color-picker v-model="form.thankyou_cover.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                            </Field>
                                                            <ErrorMessage name="overlay_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Overlay Opacity <a class="reset-default" @click="resetToDefault('thankyou_cover', 'overlay_opacity')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="nofill" name="overlay_opacity" v-model="form.thankyou_cover.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="overlay opacity" />
                                                            </div>
                                                            <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Cover Box Color</label>
                                                        <Field autocomplete="off" name="cover_box_color" v-model="form.thankyou_settings.cover_box_color" type="text" label="element color">
                                                            <color-picker v-model="form.thankyou_settings.cover_box_color" :classes="{ 'has-error': errors.cover_box_color }" />
                                                        </Field>
                                                        <ErrorMessage name="cover_box_color" class="text-danger" />
                                                    </div>
                                                </div> -->
                                            </div>
                                            <h3 class="sub_header mt-5">Headline</h3>
                                            <div class="edit_section">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">
                                                            Headline Text
                                                            <div class="flex-center">
                                                                <button type="button" class="reset-default" @click="resetToDefault('thankyou_settings', 'headline')">Reset to default</button>
                                                                <button type="button" class="px-1" @click="showThankyouHeadlineSetting = !showThankyouHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                            </div>
                                                        </label>
                                                        <redactor name="thankyou_headline" v-model="form.thankyou_settings.headline" :default-value="newsletterDefaultValues.thankyou_headline" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                                    </div>
                                                </div>
                                                <div v-show="showThankyouHeadlineSetting">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'headline_text_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="thank_cover_textcolor" v-model="form.thankyou_settings.headline_text_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.headline_text_color" :classes="{ 'has-error': errors.thank_cover_textcolor }" />
                                                            </Field>
                                                            <ErrorMessage name="thank_cover_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 class="sub_header mt-5">Sub Headline</h3>
                                            <div class="edit_section mb-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">
                                                            Sub Headline Text
                                                            <div class="flex-center">
                                                                <button type="button" class="reset-default" @click="resetToDefault('thankyou_settings', 'sub_headline')">Reset to default</button>
                                                                <button type="button" class="px-1" @click="showThankyouSubheadlineSetting = !showThankyouSubheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                            </div>
                                                        </label>
                                                        <redactor name="thankyou_sub_headline" v-model="form.thankyou_settings.sub_headline" :default-value="newsletterDefaultValues.thankyou_sub_headline" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                                    </div>
                                                </div>
                                                <div v-show="showThankyouSubheadlineSetting">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'sub_headline_text_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="thank_subheadline_setting_font_color" v-model="form.thankyou_settings.sub_headline_text_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.sub_headline_text_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                            </Field>
                                                            <ErrorMessage name="thank_subheadline_setting_font_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="edit_section mt-5">
                                                <h3 class="sub_header mt-2">Coach
                                                    <label for="coach_img" class="switch_option capsule_btn p-0">
                                                        <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.thankyou_settings.display_coach" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </h3>
                                            </div>
                                            <div class="edit_section mt-5">
                                                <h3 class="sub_header mt-2">
                                                    Video
                                                    <label for="thank_video" class="switch_option capsule_btn p-0">
                                                        <input type="checkbox" id="thank_video" :true-value="1" :false-value="0" v-model="form.thankyou_settings.has_video" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </h3>
                                                <div class="edit_section mb-2" v-if="form.thankyou_settings.has_video">
                                                    <div class="setting_wpr">
                                                        <div class="border-bottom pb-3">
                                                            <label for="thumb_url" class="switch_option capsule_btn border-0">
                                                                <h5 class="large">Video Link (works with Vimeo, YouTube, Wisita etc.)</h5>
                                                                <input type="radio" id="thumb_url" :value="1" v-model="form.thankyou_settings.video_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div class="setting_wpr" v-if="(form.thankyou_settings.video_type == 1)">
                                                                <div class="form_grp py-2">
                                                                    <div class="group_item">
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.video_link }">
                                                                            <Field autocomplete="off" type="text" name="video_link" v-model="form.thankyou_settings.video_link" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="required|url" label="video link" />
                                                                        </div>
                                                                        <ErrorMessage name="video_link" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="py-3">
                                                            <label for="banner_video" class="switch_option capsule_btn">
                                                                <h5 class="large">Embeded Video</h5>
                                                                <input type="radio" id="banner_video" :value="2" v-model="form.thankyou_settings.video_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div class="setting_wpr" v-if="form.thankyou_settings.video_type == 2">
                                                                <div class="form_grp py-2">
                                                                    <div class="group_item">
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.thumbnail_embed }">
                                                                            <Field autocomplete="off" type="textarea" name="thumbnail_embed" v-model="form.thankyou_settings.video_embed" rules="required" label="embed code">
                                                                                <textarea name="thumbnail_embed" v-model="form.thankyou_settings.video_embed" placeholder="Please add your embed script"></textarea>
                                                                            </Field>
                                                                        </div>
                                                                        <ErrorMessage name="thumbnail_embed" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 class="sub_header mt-5">Steps</h3>
                                            <div class="edit_section mb-4" v-for="(step, s) in thankyouSteps" :key="s">
                                                <div class="step_number">
                                                    Step {{(s + 1)}}
                                                    <label :for="`step${s}`" class="switch_option capsule_btn p-0 ml-auto">
                                                        <input type="checkbox" :id="`step${s}`" :true-value="1" :false-value="0" v-model="thankyouSteps[s].is_enabled" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <button type="button" class="delete_btn" @click="handleDeleteStep(step)"><i class="fas fa-trash-alt"></i></button>
                                                </div>
                                                <template v-if="thankyouSteps[s].is_enabled">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Step Title <a v-if="step.primary_id" class="reset-default" @click="resetToDefault('thankyou_steps', s, 'title')">Reset to default</a></label>
                                                            <redactor :name="`thankyou_steps_title_${s}`" v-model="thankyouSteps[s].title" :default-value="newsletterDefaultValues.thankyou_steps[s].title" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Step Info <a v-if="step.primary_id" class="reset-default" @click="resetToDefault('thankyou_steps', s, 'info')">Reset to default</a></label>
                                                            <redactor :name="`thankyou_steps_info_${s}`" v-model="thankyouSteps[s].info" :default-value="newsletterDefaultValues.thankyou_steps[s].info" :reset-watcher="redactorResetWatcher" min-height="20px" :show-font-highlight="true" :plugins="redactorPlugins" :hide-toolbars="redactorHideToolbars" />
                                                        </div>
                                                    </div>
                                                    <h3 class="sub_header">
                                                        CTA For Step {{s + 1}}
                                                        <label :for="`step_cta_${s}`" class="switch_option capsule_btn p-0">
                                                            <input type="checkbox" :id="`step_cta_${s}`" :true-value="1" :false-value="0" v-model="thankyouSteps[s].has_button" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </h3>
                                                    <div class="edit_section mb-4" v-if="thankyouSteps[s].has_button">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">CTA Button Text <a v-if="step.primary_id" class="reset-default" @click="resetToDefault('thankyou_steps', s, 'button_text')">Reset to default</a></label>
                                                                <div class="field_wpr">
                                                                    <Field autocomplete="off" type="text" :name="`step_btn_text_${s}`" v-model="thankyouSteps[s].button_text" placeholder="Title goes here.."/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">CTA Button Color <a v-if="step.primary_id" class="reset-default" @click="resetToDefault('thankyou_steps', s, 'button_bg_color')">Reset to default</a></label>
                                                                <Field autocomplete="off" :name="`step_btn_clr_${s}`" v-model="thankyouSteps[s].button_bg_color" type="text" label="element color">
                                                                    <color-picker v-model="thankyouSteps[s].button_bg_color" :classes="{ 'has-error': errors.btn_clr }" />
                                                                </Field>
                                                                <ErrorMessage :name="`step_btn_clr_${s}`" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">CTA Button Text Color <a v-if="step.primary_id" class="reset-default" @click="resetToDefault('thankyou_steps', s, 'button_text_color')">Reset to default</a></label>
                                                                <Field autocomplete="off" :name="`step_btn_text_clr_${s}`" v-model="thankyouSteps[s].button_text_color" type="text" label="element color">
                                                                    <color-picker v-model="thankyouSteps[s].button_text_color" :classes="{ 'has-error': errors.btn_text_clr }" />
                                                                </Field>
                                                                <ErrorMessage :name="`step_btn_text_clr_${s}`" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">CTA Button Link</label>
                                                                <div class="field_wpr has_prefix">
                                                                    <Field autocomplete="off" type="text" :name="`step_btn_link_${s}`" v-model="thankyouSteps[s].button_link" placeholder="https://superfitcoaching.com" rules="url_bar" label="cta link" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage :name="`step_btn_link_${s}`" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <button type="button" class="add_btn ml-auto mb-3 mr-0" @click="addStep"><i class="fas fa-plus"></i>Add New Step</button>
                                            <h3 class="sub_header mt-5">
                                                Downloads
                                                <label for="download" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="download" :true-value="1" :false-value="0" v-model="form.thankyou_settings.has_download" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </h3>
                                            <div class="edit_section" v-if="form.thankyou_settings.has_download">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Headline Text <a class="reset-default" @click="resetToDefault('thankyou_settings', 'download_headline')">Reset to default</a></label>
                                                        <div class="field_wpr">
                                                            <Field autocomplete="off" type="text" name="download_text" v-model="form.thankyou_settings.download_headline" placeholder="Headline goes here.."/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 class="sub_header">Upload Files</h3>
                                                <newsletter-download v-model="form" />
                                            </div>

                                            <h3 class="sub_header mt-5">
                                                Call To Action
                                                <label for="cta" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="cta" :true-value="1" :false-value="0" v-model="form.thankyou_settings.has_cta" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </h3>
                                            <div class="edit_section" v-if="form.thankyou_settings.has_cta">
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Headline <a class="reset-default" @click="resetToDefault('thankyou_settings', 'cta_headline')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="cta_headline" v-model="form.thankyou_settings.cta_headline"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Sub Headline <a class="reset-default" @click="resetToDefault('thankyou_settings', 'cta_sub_headline')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="cta_subheadline" v-model="form.thankyou_settings.cta_sub_headline"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Button Text <a class="reset-default" @click="resetToDefault('thankyou_settings', 'cta_button_text')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="cta_btn_text" v-model="form.thankyou_settings.cta_button_text"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Button Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'cta_button_bg_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="cta_btn_clr" v-model="form.thankyou_settings.cta_button_bg_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.cta_button_bg_color" :classes="{ 'has-error': errors.btn_clr }" />
                                                            </Field>
                                                            <ErrorMessage name="cta_btn_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Button Text Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'cta_button_text_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="btn_text_clr" v-model="form.thankyou_settings.cta_button_text_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.cta_button_text_color" :classes="{ 'has-error': errors.btn_text_clr }" />
                                                            </Field>
                                                            <ErrorMessage name="btn_text_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">CTA Button Link</label>
                                                            <div class="field_wpr has_prefix">
                                                                <Field autocomplete="off" type="text" name="btn_link" v-model="form.thankyou_settings.cta_button_link" placeholder="https://superfitcoaching.com" rules="url_bar" label="cta link" />
                                                                <span class="prefix">https://</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="btn_link" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 class="sub_header mt-5">Copyright</h3>
                                            <div class="edit_section mb-4">
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Copyright Text <a class="reset-default" @click="resetToDefault('thankyou_settings', 'copyright_text')">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="copyright" v-model="form.thankyou_settings.copyright_text"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Text Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'copyright_text_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="icon_clr" v-model="form.thankyou_settings.copyright_text_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.copyright_text_color" :classes="{ 'has-error': errors.clr }" />
                                                            </Field>
                                                            <ErrorMessage name="icon_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="resetToDefault('thankyou_settings', 'copyright_bg_color')">Reset to default</a></label>
                                                            <Field autocomplete="off" name="bg_clr" v-model="form.thankyou_settings.copyright_bg_color" type="text" label="element color">
                                                                <color-picker v-model="form.thankyou_settings.copyright_bg_color" :classes="{ 'has-error': errors.bg_clr }" />
                                                            </Field>
                                                            <ErrorMessage name="bg_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 3">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notification To Contact</h2>
                                    <label for="contact-notification-switch" class="switch_option capsule_btn">
                                        <input type="checkbox" id="contact-notification-switch" :true-value="1" :false-value="0" v-model="form.has_contact_notification" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <sending-method v-model="form.contact_notification.sending_method" />
                                        <div class="mt-5" v-if="form.contact_notification.sending_method == 1 || form.contact_notification.sending_method == 3">
                                            <email-component v-model="contactEmail" :errors="errors" :has-email-button="false" :handle-default-email="handleContactDefaultEmail" preview-module="newsletter" ref="newsletter-contact-email-component"  />
                                        </div>
                                        <div class="mt-5" v-if="form.contact_notification.sending_method == 2 || form.contact_notification.sending_method == 3">
                                            <sms-component v-model="form.contact_notification.sms_content" media-field-name="contact_sms_media" :media="form.contact_notification.media" :default-sms-handler="true" preview-module="newsletter" :handle-default-sms="handleContactDefaultSms" ref="newsletter-contact-sms-component" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 4">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notification To Admin</h2>
                                    <label for="admin-notification-switch" class="switch_option capsule_btn">
                                        <input type="checkbox" id="admin-notification-switch" :true-value="1" :false-value="0" v-model="form.has_admin_notification" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <sending-method v-model="form.admin_notification.sending_method" />

                                        <div class="mt-5" v-if="form.admin_notification.sending_method == 1 || form.admin_notification.sending_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Email Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                        <Field autocomplete="off" name="notification_email_recipient" v-model="form.admin_notification.email_recipient" rules="required" label="email recipient">
                                                            <vue-tags-input :tags="form.admin_notification.email_recipient" @on-tags-changed="handleAdminEmailRecipient" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                            <email-component v-model="adminEmail" :errors="errors" :has-email-button="false" :handle-default-email="handleAdminDefaultEmail" preview-module="newsletter" ref="newsletter-admin-email-component" is-full-view />
                                        </div>
                                        <div class="mt-5" v-if="form.admin_notification.sending_method == 2 || form.admin_notification.sending_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">SMS Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                        <Field autocomplete="off" name="notification_sms_recipient" v-model="form.admin_notification.sms_recipient" rules="required" label="sms recipient">
                                                            <vue-tags-input :tags="form.admin_notification.sms_recipient" @on-tags-changed="handleAdminSMSRecipient" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                            <sms-component v-model="form.admin_notification.sms_content" media-field-name="admin_sms_media" :media="form.admin_notification.media" :default-sms-handler="true" preview-module="newsletter" :handle-default-sms="handleAdminDefaultSms" ref="newsletter-admin-sms-component" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step == 5">
                            <div class="section_content w-100" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="page_indexed" class="switch_option capsule_btn border-0">
                                        <h5 class="large">I want my page indexed by search engine</h5>
                                        <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.seo_index" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tracking</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Facebook Pixel Code</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                    <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.seo_fb_pixel" label="description" rules="fb_pixel">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.seo_fb_pixel"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="fb_pixel" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <image-library v-model="form.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="newsletterUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="newsletterUpdateLoader" type="button" class="btn cancel_btn" @click="step--" v-if="step > 0">Prev</button>
                        <button :disabled="newsletterUpdateLoader" class="btn save_btn" v-if="step < 5">
                            <i class="fa fa-spin fa-spinner" v-if="newsletterUpdateLoader"></i>&nbsp; {{ newsletterUpdateLoader ? 'Updating' : 'Next'}}
                        </button>
                        <button :disabled="newsletterUpdateLoader" class="btn save_btn" v-if="step == 5">
                            <i class="fa fa-spin fa-spinner" v-if="newsletterUpdateLoader"></i>&nbsp; {{ newsletterUpdateLoader ? 'Updating' : 'Finish'}}
                        </button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step < 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_newsletter_setting" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_newsletter_setting" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev" v-if="step == 3 && form.contact_notification">
                    <li @click="notificationPreviewTab = 'email'" :class="{'active' : notificationPreviewTab == 'email'}" v-if="(form.contact_notification.sending_method == 1) || (form.contact_notification.sending_method == 3)">E-mail</li>
                    <li @click="notificationPreviewTab = 'sms'" :class="{'active' : notificationPreviewTab == 'sms'}" v-if="(form.contact_notification.sending_method == 2) || (form.contact_notification.sending_method == 3)">SMS</li>
                </ul>
                <ul class="alt_prev" v-if="step == 4 && form.admin_notification">
                    <li @click="notificationPreviewTab = 'email'" :class="{'active' : notificationPreviewTab == 'email'}" v-if="(form.admin_notification.sending_method == 1) || (form.admin_notification.sending_method == 3)">E-mail</li>
                    <li @click="notificationPreviewTab = 'sms'" :class="{'active' : notificationPreviewTab == 'sms'}" v-if="(form.admin_notification.sending_method == 2) || (form.admin_notification.sending_method == 3)">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area">
                        <div class="newsletter" v-if="step < 2">
                            <div class="nl_row lm-mdl-type" :class="form.template">
                                <div class="nl_bg_cover" :style="{ backgroundImage: form.cover.mob_img ? 'url(' + form.cover.mob_img + ')' : 'url(' + form.cover.main_img + ')' }">
                                    <span class="overlay" v-if="form.cover.has_overlay" :style="`background-color: ${form.cover.overlay_color}; opacity: ${form.cover.overlay_opacity == 0 ? '0': (form.cover.overlay_opacity / 100 )};`"></span>
                                </div>
                                <!-- <div class="user_img" v-if="form.coach.has_coach" :class="`coach-${form.coach.align}`">
                                    <img :src="form.coach.image ? form.coach.image : require('@/assets/images/male-female.png')" alt="">
                                    <div class="details">
                                        <h4>{{ form.coach.name }}</h4>
                                        <h6>{{ form.coach.credentials }}</h6>
                                    </div>
                                </div> -->
                                <div class="nl_content">
                                    <div class="nl_container">
                                        <div class="nl_header">
                                            <div class="heading_affix text-left" v-show="form.call_out.has_call_out" v-html="form.call_out.text" :style="`color: ${form.call_out.text_color};`"></div>
                                            <div class="heading text-left" v-html="form.headline.text" :style="`color: ${form.headline.text_color}; ${form && form.headline && form.headline.has_shadow ? 'text-shadow: ' + form.headline.shadow_x +'px ' + form.headline.shadow_y + 'px ' + form.headline.shadow_blur + 'px ' + form.headline.shadow_color : 'none'}`"></div>
                                            <div class="sub_heading text-left" v-html="form.sub_headline.text" :style="`color: ${form.sub_headline.text_color};`"></div>
                                        </div>
                                        <ul class="feature_list" v-if="form.has_benefit">
                                            <li v-for="(benefit, b) in form.benefit.benefits" :key="b" :style="{ color: form.benefit.text_color }">
                                                <newsletter-icon class="newsletter-preview-icon" :icon="form.benefit.icon" :color="form.benefit.icon_color" /> {{ benefit }}
                                            </li>
                                        </ul>
                                        <button type="button" class="primary_btn btn-upper" :style="`background: ${form.cta_button.bg_color}; color: ${form.cta_button.text_color};`" @click="newsletterPopup = true">{{ form.cta_button.text }}</button>
                                    </div>
                                    <h6 class="copyright" :style="{ color: form.copyright.text_color }">© {{ replaceVariables(form.copyright.text) }} <a :style="{ color: form.copyright.text_color }" href="https://thrivecoach.io">Powered by Thrive Coach</a></h6>
                                </div>
                                <div class="popup" v-if="newsletterPopup">
                                    <div class="popup_container">
                                        <button type="button" class="close_btn" :style="{ background: form.optin_submit_button.action_button_bg_color, borderColor: form.optin_submit_button.action_button_bg_color, color: form.optin_submit_button.action_button_text_color }" @click="newsletterPopup = false;"><i class="fas fa-times"></i></button>
                                        <div class="left">
                                            <picture>
                                                <source media="(max-width:425px)" :srcset="form.popup.mob_img">
                                                <img :src="form.popup.mob_img ? form.popup.mob_img : form.popup.main_img">
                                            </picture>
                                        </div>
                                        <div class="right">
                                            <h1 :style="{ fontFamily: form.popup.headline_font_family, fontWeight: form.popup.headline_font_weight, color: form.popup.headline_text_color }">{{ form.popup.headline_text }}</h1>
                                            <div class="setting_wpr">
                                                <div class="form_grp" v-if="form.optin_fields.first_name">
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <input type="text" placeholder="First name" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp" v-if="form.optin_fields.last_name">
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <input type="text" placeholder="Last name" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp" v-if="form.optin_fields.email">
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <input type="email" placeholder="Email address" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp" v-if="form.optin_fields.phone">
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <input type="text" placeholder="Phone number" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <label class="checkbox gdpr" for="gdpr" v-if="form.has_gdpr">
                                                    <input type="checkbox" name="" id="gdpr" hidden>
                                                    <span><i class="fas fa-check"></i></span><p>{{ replaceVariables(form.gdpr_permission) }}</p>
                                                </label>
                                                <button type="button" class="primary_btn btn-upper" :style="{ background: form.optin_submit_button.action_button_bg_color, color: form.optin_submit_button.action_button_text_color }">{{ form.optin_submit_button.action_button_text }}</button>
                                                <div class="security" v-if="form.privacy.has_privacy">
                                                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path :fill="form.privacy.icon_color" d="M1.54927 9.84927C1.359 9.84927 1.19727 9.78267 1.06407 9.64948C0.930884 9.51629 0.864288 9.35456 0.864288 9.16428V4.20958C0.864288 4.01931 0.930884 3.85758 1.06407 3.72439C1.19727 3.5912 1.359 3.5246 1.54927 3.5246H2.34842V2.42863C2.34842 1.82737 2.55962 1.31553 2.98202 0.89313C3.40443 0.470724 3.91626 0.259521 4.51753 0.259521C5.11879 0.259521 5.63062 0.470724 6.05303 0.89313C6.47543 1.31553 6.68664 1.82737 6.68664 2.42863V3.5246H7.48578C7.67605 3.5246 7.83779 3.5912 7.97098 3.72439C8.10417 3.85758 8.17076 4.01931 8.17076 4.20958V9.16428C8.17076 9.35456 8.10417 9.51629 7.97098 9.64948C7.83779 9.78267 7.67605 9.84927 7.48578 9.84927H1.54927ZM1.54927 9.16428H7.48578V4.20958H1.54927V9.16428ZM4.51753 7.56599C4.76108 7.56599 4.96847 7.48227 5.13972 7.31483C5.31096 7.14739 5.39659 6.9457 5.39659 6.70977C5.39659 6.48144 5.31096 6.27404 5.13972 6.08757C4.96847 5.90111 4.76108 5.80787 4.51753 5.80787C4.27398 5.80787 4.06658 5.90111 3.89533 6.08757C3.72409 6.27404 3.63847 6.48144 3.63847 6.70977C3.63847 6.9457 3.72409 7.14739 3.89533 7.31483C4.06658 7.48227 4.27398 7.56599 4.51753 7.56599ZM3.0334 3.5246H6.00165V2.42863C6.00165 2.01764 5.85705 1.66754 5.56783 1.37832C5.27862 1.08911 4.92852 0.944503 4.51753 0.944503C4.10654 0.944503 3.75643 1.08911 3.46722 1.37832C3.17801 1.66754 3.0334 2.01764 3.0334 2.42863V3.5246ZM1.54927 9.16428V4.20958V9.16428Z" />
                                                    </svg>
                                                    {{ form.privacy.text }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="newsletter_thankyou" v-if="step == 2 && form.thankyou_type == 2">
                            <div class="nl_cover" :style="{ backgroundImage: form.thankyou_cover.mob_img ? 'url(' + form.thankyou_cover.mob_img + ')' : 'url(' + form.thankyou_cover.main_img + ')' }">
                                <span class="overlay" v-if="form.thankyou_cover.has_overlay" :style="`background-color: ${form.thankyou_cover.overlay_color}; opacity: ${form.thankyou_cover.overlay_opacity == 0 ? '0': (form.thankyou_cover.overlay_opacity / 100 )};`"></span>
                                <div class="nl_header">
                                    <div class="heading text-center" v-html="form.thankyou_settings && form.thankyou_settings.headline ? replaceVariables(form.thankyou_settings.headline) : ''" :style="`color: ${form.thankyou_settings.headline_text_color};`"></div>
                                    <div class="sub_heading text-center" v-html="form.thankyou_settings && form.thankyou_settings.sub_headline ? replaceVariables(form.thankyou_settings.sub_headline) : ''" :style="`color: ${form.thankyou_settings.sub_headline_text_color};`"></div>
                                </div>
                            </div>
                            <div class="ty_container">
                                <div class="user_img" v-if="form.thankyou_settings.display_coach">
                                    <img :src="form.coach.image ? form.coach.image : require('@/assets/images/male-female.png')" alt="">
                                    <div class="details">
                                        <h4>{{ form.coach.name }}</h4>
                                        <h6>{{ form.coach.credentials }}</h6>
                                    </div>
                                </div>
                                <div class="content_section w-100">
                                    <div class="video_wpr" v-if="form.thankyou_settings && form.thankyou_settings.has_video">
                                        <div v-if="form.thankyou_settings && form.thankyou_settings.video_type == 2" v-html="form.thankyou_settings.video_embed"></div>
                                        <div v-else-if="form.thankyou_settings && form.thankyou_settings.video_type == 1" v-html="parseEmbedCode(form.thankyou_settings.video_link)"></div>
                                    </div>
                                    <div class="step_listing">
                                        <ul v-if="thankyouSteps && thankyouSteps.length">
                                            <li v-for="(step, s) in thankyouSteps.filter((step) => step.is_enabled)" :key="s" v-show="step.is_enabled">
                                                <span class="step">{{s + 1}}</span>
                                                <h3 v-html="step.title"></h3>
                                                <p class="word-break" v-html="replaceVariables(step.info)"></p>
                                                <a v-if="step.has_button" :href="step.button_link" target="_blank" class="join" :style="`background-color: ${step.button_bg_color}; color:${step.button_text_color}`">{{ step.button_text }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="downloads" v-if="form.thankyou_settings && form.thankyou_settings.has_download">
                                        <h3>{{ form.thankyou_settings.download_headline }}</h3>
                                        <ul class="download_list">
                                            <li v-for="(download, d) of downloads" :key="d">
                                                <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                    <i class="far fa-file-excel mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                    <i class="far fa-file-image mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['pdf'].includes(download.ext)">
                                                    <i class="far fa-file-pdf mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                    <i class="far fa-file-video mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                    <i class="far fa-file-word mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                    <i class="far fa-file-powerpoint mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                    <i class="far fa-file-audio mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else>
                                                    <i class="far fa-file mr-1"></i> {{ download.filename }}
                                                </h5>
                                                <span>
                                                    <i class="fas fa-cloud-download-alt pointer"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="ty_footer">
                                <div class="download_area" v-if="form.thankyou_settings && form.thankyou_settings.has_cta">
                                    <h3>
                                        {{ form.thankyou_settings && form.thankyou_settings.cta_headline ? form.thankyou_settings.cta_headline : ''}}
                                        <span>{{ form.thankyou_settings && form.thankyou_settings.cta_sub_headline ? form.thankyou_settings.cta_sub_headline : ''}}</span>
                                    </h3>
                                    <a :style="`color: ${form.thankyou_settings.cta_button_text_color}; background: ${form.thankyou_settings.cta_button_bg_color}`" :href="form.thankyou_settings && form.thankyou_settings.cta_button_link ? form.thankyou_settings.cta_button_link : '#'" target="_blank" class="cta_btn">{{ form.thankyou_settings && form.thankyou_settings.cta_button_text ? form.thankyou_settings.cta_button_text : '' }}</a>
                                </div>
                                <h6 :style="`color: ${form.thankyou_settings.copyright_text_color}; background: ${form.thankyou_settings.copyright_bg_color}`"> © {{ form.thankyou_settings && form.thankyou_settings.copyright_text ? replaceVariables(form.thankyou_settings.copyright_text) : '' }}
                                    <a :style="`color: ${form.thankyou_settings.copyright_text_color}; background: ${form.thankyou_settings.copyright_bg_color}`" href="https://thrivecoach.io" target="_blank">Powered by Thrive Coach</a>
                                </h6>
                            </div>
                        </div>
                        <div class="newsletter_redirect" v-if="step == 2 && form.thankyou_type == 1">
                            <div class="redirect_add">
                                <label class="prefix_area"><i class="fas fa-globe-americas"></i><span></span></label>
                                <input type="text" v-model="form.thankyou_settings.redirect_url">
                                <span class="btn"><i class="fas fa-search"></i></span>
                            </div>
                            <img src="@/assets/images/redirect.svg" alt="">
                        </div>
                        <div v-if="step == 3">
                            <div v-if="(form.contact_notification && ((notificationPreviewTab == 'email' && form.contact_notification.sending_method == 3) || form.contact_notification.sending_method == 1))">
                                <div class="msg_preview">
                                    <h2 v-if="contactEmail.subject">{{ replaceVariables(contactEmail.subject) }}</h2>
                                    <div class="sender_info">
                                        <img src="@/assets/images/default-avatar.svg" alt="">
                                        <div class="info">
                                            <h5>Thrive Coach</h5>
                                            <h6><span>To:</span>{{ user.full_name }}</h6>
                                        </div>
                                    </div>
                                    <div class="email-logo" v-if="contactEmail.show_email_logo">
                                        <img :src="contactEmail.email_logo ? contactEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                                    </div>
                                    <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(contactEmail.email, contactEmail.email_button_bg_color, contactEmail.email_button_text_color)"></div>
                                    <div v-html="generateSignature(contactEmail.is_signature, contactEmail.signature_id)"></div>
                                </div>
                                <div class="msgFooter text-center mt-2 mb-2">
                                    <p>
                                        <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                            <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                            <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                        </template>
                                        <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                                    </p>
                                    <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                                    <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                                </div>
                            </div>
                            <div class="sms_preview" v-if="(form.contact_notification && ((notificationPreviewTab == 'sms' && form.contact_notification.sending_method == 3) || form.contact_notification.sending_method == 2))">
                                <pre>{{ replaceVariables(form.contact_notification.sms_content) }}</pre>
                            </div>
                        </div>
                        <div v-if="step == 4">
                            <div v-if="(form.admin_notification && ((notificationPreviewTab == 'email' && form.admin_notification.sending_method == 3) || form.admin_notification.sending_method == 1))">
                                <div class="msg_preview">
                                    <h2 v-if="adminEmail.subject">{{ replaceVariables(adminEmail.subject) }}</h2>
                                    <div class="sender_info">
                                        <img src="@/assets/images/default-avatar.svg" alt="">
                                        <div class="info">
                                            <h5>Thrive Coach</h5>
                                            <h6><span>To:</span>{{ user.full_name }}</h6>
                                        </div>
                                    </div>
                                    <div class="email-logo" v-if="adminEmail.show_email_logo">
                                        <img :src="adminEmail.email_logo ? adminEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                                    </div>
                                    <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(adminEmail.email, adminEmail.email_button_bg_color, adminEmail.email_button_text_color)"></div>
                                    <div v-html="generateSignature(adminEmail.is_signature, adminEmail.signature_id)"></div>
                                </div>
                                <div class="msgFooter text-center mt-4 pt-1 mb-2">
                                    <p>
                                        <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                            <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                            <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                        </template>
                                        <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                                    </p>
                                    <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                                    <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                                </div>
                            </div>
                            <div class="sms_preview" v-if="(form.admin_notification && ((notificationPreviewTab == 'sms' && form.admin_notification.sending_method == 3) || form.admin_notification.sending_method == 2))">
                                <pre>{{ replaceVariables(form.admin_notification.sms_content) }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step == 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.seo_title }}</h4>
                        <p>{{ form.seo_desc }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.seo_thumb ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.seo_share_title ? form.seo_share_title : 'Get your Newsletter' }}</h3>
                            <p>{{ form.seo_share_desc ? form.seo_share_desc : 'We’re pretty sure you will like our newsletters with lots of news, great offers and more.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <newsletter-cropper v-model="coverCropper" :form-data="form.cover" type="cover" :on-crop="handleNewsletterCoverCrop" />
        <newsletter-cropper v-model="popupCropper" :has-tab="false" :form-data="form.popup" type="popup" :on-crop="handleNewsletterPopupCrop" />
        <newsletter-cropper v-model="thankyouCropper" :form-data="form.thankyou_cover" type="thankyou_cover" :on-crop="handleNewsletterThankyouCrop" />
        <full-preview :step="step" v-model="fullPreview" :newsletter="form" />
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const NewsletterCropper = defineAsyncComponent(() => import('@/pages/optin/components/NewsletterCropper'))
    const NewsletterDownload = defineAsyncComponent(() => import('@/pages/optin/components/NewsletterDownload'))
    const NewsletterIcon = defineAsyncComponent(() => import('@/pages/optin/components/Icon'))
    const FullPreview = defineAsyncComponent(() => import('@/pages/optin/components/FullPreview'))

    import VueTagsInput from 'vue3-tags-input'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import constants from '@/utils/Constant'
    import VideoParser from '@/utils/VideoParser'


    export default {
        name: 'Newsletter Configure',

        data () {
            return {
                fullPreview: false,
                newsletterPopup: false,
                calloutSetting: false,
                showHeadlineSetting: false,
                showSubHeadlineSetting: false,
                redactorResetWatcher: 0,
                preview: true,
                step: 0,
                redactorPlugins: ['removeformat', 'fontcolor-custom'],
                redactorHideToolbars: ['format', 'html', 'underline', 'lists', 'file', 'deleted'],
                icons: ['check', 'bullet', 'chevron', 'heart', 'triangle', 'kettlebell'],
                coverCropper: false,
                popupCropper: false,
                thankyouCropper: false,
                form: {
                    name: '',
                    category: 'newsletter',
                    has_benefit:  1,
                    thumbnail: '',
                    cover: {
                                main_img: '',
                                desk_img: '',
                                tab_img: '',
                                mob_img: '',
                                desk_coordinate: {},
                                tab_coordinate: {},
                                mob_coordinate: {},
                                overlay_color: '',
                            },
                    call_out: {
                                    has_call_out:  1,
                                    text: '',
                                    text_color: '',
                                },
                    headline: {
                                    text: '',
                                    text_color: '',
                                    has_shadow: 0,
                                    shadow_x: 0,
                                    shadow_y: 1,
                                    shadow_blur: 2,
                                    shadow_color: 'rgba(0, 0, 0, 0.6)',
                                },
                    sub_headline: {
                                      text: '',
                                      text_color: '',
                                  },
                    benefit: {
                                  benefits: [],
                                  text_color: '#FFFFFF',
                                  icon_color: '#43F4FF',
                                  icon: 'check',
                              },
                    cta_button: {
                                      text: '',
                                      text_color: '',
                                      bg_color: '',
                                  },
                    coach: {
                                has_coach:  1,
                                image: '',
                                name: '',
                                credentials: '',
                                align: 'top-right',
                            },
                    copyright: {
                                    text: '',
                                    text_color: '',
                                },
                    tags: [],
                    tab: '',
                    popup: {
                                main_img: '',
                                desk_img: '',
                                tab_img: '',
                                mob_img: '',
                                desk_coordinate: {},
                                tab_coordinate: {},
                                mob_coordinate: {},
                                headline_text: '',
                                headline_font_family: '',
                                headline_font_weight: '',
                                headline_text_color: '',
                            },
                    optin_submit_button: {
                                              action_button_text: '',
                                              action_button_text_color: '',
                                              action_button_bg_color: '',
                                          },
                    optin_fields: {first_name: 1, last_name: 1, email: 1, phone: 1},
                    gdpr_permission: '',
                    privacy: {
                                  text: '',
                                  icon_color: '',
                                  has_privacy: 1,
                              },
                    thankyou_type:  1,
                    thankyou_cover: {
                                    main_img: '',
                                    desk_img: '',
                                    tab_img: '',
                                    mob_img: '',
                                    desk_coordinate: {},
                                    tab_coordinate: {},
                                    mob_coordinate: {},
                                    overlay_color: '#15151580',
                                    box_color: '#FFFFFF',
                                },
                    thankyou_settings: {
                                            redirect_url: '',
                                            headline: '',
                                            headline_text_color: '',
                                            sub_headline: '',
                                            sub_headline_text_color: '',
                                            display_coach:  1,
                                            has_download:  0,
                                            download_headline: '',
                                            has_video:  0,
                                            video_type:  1,
                                            video_link: '',
                                            video_embed: '',
                                            has_cta:  1,
                                            cta_headline: '',
                                            cta_sub_headline: '',
                                            cta_button_text: '',
                                            cta_button_link: '',
                                            cta_button_text_color: '',
                                            cta_button_bg_color: '',
                                            copyright_text: '',
                                            copyright_text_color: '',
                                            copyright_bg_color: '',
                                        },
                    thankyou_steps: [
                                       {
                                            title: '',
                                            info: '',
                                            is_enabled:  1,
                                            has_button:  1,
                                            button_text: '',
                                            button_link: '',
                                            button_text_color: '',
                                            button_bg_color: '',
                                        },
                                    ],
                    has_contact_notification:  1,
                    contact_notification: {
                                              sending_method:  1,
                                              subject:  '',
                                              email_content: '',
                                              sms_content: '',
                                              media: [],
                                              is_signature:  0,
                                              signature_id:  0,
                                              show_email_logo:  0,
                                              email_logo: '',
                                          },
                    has_admin_notification:  1,
                    admin_notification: {
                                            sending_method:  1,
                                            subject:  '',
                                            email_content: '',
                                            sms_content: '',
                                            media: [],
                                            email_recipient: [],
                                            sms_recipient: [],
                                            is_signature:  0,
                                            signature_id:  0,
                                            show_email_logo:  0,
                                            email_logo: '',
                                        },
                    seo_title: '',
                    seo_keyword: '',
                    seo_desc: '',
                    seo_index: 1,
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    seo_fav: '',
                },
                createTags: false,
                contactEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                adminEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    email_recipient: [],
                    sms_recipient: [],
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'newsletter',
                },
                showThankyouHeadlineSetting: false,
                showThankyouSubheadlineSetting: false,
                thankyouUplodeFile: '',
                thankyouSteps: [],
                thnakyouDownloads: [],
                gdprPersonalizeOption: false,
                defaultPersonalize: [],
                downloads: [],
                notificationPreviewTab: 'email',
                env: {},
                contact_sms_media: [],
                admin_sms_media: [],
                newsletterDefaultValues: {
                    call_out: '',
                    headline: '',
                    sub_headline: '',
                    thankyou_headline: '',
                    thankyou_sub_headline: '',
                    thankyou_steps: []
                },
                isMounted: false,
            }
        },

        props: {
            modelValue: Boolean,
            selectedNewsletter: {
                type: Object,
                default: () => {}
            },
            isEdit: {
                type: Boolean,
                default: false,
            },
            refreshNewsletter: Function,
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            VueTagsInput,
            NewsletterCropper,
            NewsletterDownload,
            NewsletterIcon,
            FullPreview,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.isMounted = false;

                    setTimeout(function () {
                        vm.isMounted = true;
                    }, 3000);

                    vm.resetForm(true);

                    const newsletterId = vm.selectedNewsletter.is_default ?  vm.selectedNewsletter.id : vm.selectedNewsletter.template_id;
                    vm.getNewsletterTemplate(newsletterId);

                    setTimeout(function () {
                        vm.redactorResetWatcher += 1;
                    }, 100);
                }
            },

            step (step) {
                const vm = this;

                vm.newsletterPopup = step == 1 ? true : false;

                if (step == 3 || step == 4) {
                    vm.notificationPreviewTab = 'email';
                }

                setTimeout(function () {
                    vm.redactorResetWatcher += 1;
                }, 100);
            },

            newsletterDownloads (downloads) {
                const vm = this;

                vm.downloads = JSON.parse(JSON.stringify(downloads));
            },

            fullPreview (fullPreview) {
                const vm = this;

                if (fullPreview) {
                    vm.handleUpdate();
                }

                vm.toggleGistChatBox();
            },

            'form.cover.main_img' (img) {
                const vm = this;

                if (vm.isMounted) {
                    vm.form.cover.desk_img = '';
                    vm.form.cover.tab_img = '';
                    vm.form.cover.mob_img = '';
                }
            },

            'form.popup.main_img' (img) {
                const vm = this;

                if (vm.isMounted) {
                    const vm = this;

                    vm.form.popup.desk_img = '';
                    vm.form.popup.mob_img = '';
                }
            },

            'form.thankyou_cover.main_img' (img) {
                const vm = this;

                if (vm.isMounted) {
                    const vm = this;

                    vm.form.thankyou_cover.desk_img = '';
                    vm.form.thankyou_cover.tab_img = '';
                    vm.form.thankyou_cover.mob_img = '';
                }
            },

            'form.thankyou_type' (type) {
                const vm = this;

                setTimeout(function () {
                    vm.redactorResetWatcher += 1;
                }, 100);
            },
        },

        computed: {
            ...mapState({
                tagLoader: state => state.tagModule.tagLoader,
                tagsGroup: state => state.tagModule.tagsGroup,
                statusesGroup: state => state.contactModule.statusesGroup,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                user: state => state.authModule.user,
                newsletterUpdateLoader: state => state.newsletterModule.newsletterUpdateLoader,
                newsletterDownloads: state => state.newsletterModule.newsletterDownloads,
                newsletterTemplate: state => state.newsletterModule.newsletterTemplate,
                membership: state => state.authModule.membership,
                whiteLabel: state => state.authModule.whiteLabel,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
            vm.env = process.env;
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                updateNewsletter: 'newsletterModule/updateNewsletter',
                deleteStep: 'newsletterModule/deleteStep',
                getNewsletterTemplate: 'newsletterModule/getNewsletterTemplate',
            }),

            closeModal () {
                const vm = this;

                if (vm.isEdit && vm.refreshNewsletter) {
                    vm.refreshNewsletter();
                }

                vm.$emit('update:modelValue', false);
            },

            resetForm (isEdit) {
                const vm = this;

                vm.step = 0;

                if (isEdit) {
                    vm.form  = JSON.parse(JSON.stringify(vm.selectedNewsletter));

                    vm.contactEmail = {
                        defaultHandler: true,
                        subject: vm.form.contact_notification.subject,
                        email: vm.form.contact_notification.email_content,
                        is_email_logo: vm.form.contact_notification.show_email_logo,
                        is_signature: vm.form.contact_notification.is_signature,
                        signature_id: vm.form.contact_notification.signature_id,
                        show_email_logo: vm.form.contact_notification.show_email_logo,
                        email_logo: vm.form.contact_notification.email_logo,
                        module_id: vm.form.id,
                    };

                    vm.adminEmail = {
                        defaultHandler: true,
                        subject: vm.form.admin_notification.subject,
                        email: vm.form.admin_notification.email_content,
                        email_recipient: vm.form.employer_id ? vm.form.admin_notification.email_recipient : [vm.user.email],
                        sms_recipient: vm.form.employer_id ? vm.form.admin_notification.sms_recipient : [vm.user.sms_notification_number],
                        is_email_logo: vm.form.admin_notification.show_email_logo,
                        is_signature: vm.form.admin_notification.is_signature,
                        signature_id: vm.form.admin_notification.signature_id,
                        show_email_logo: vm.form.admin_notification.show_email_logo,
                        email_logo: vm.form.admin_notification.email_logo,
                        module_id: vm.form.id,
                    };

                    vm.form.admin_notification.email_recipient = vm.adminEmail.email_recipient;
                    vm.form.admin_notification.sms_recipient   = vm.adminEmail.sms_recipient;

                    vm.thankyouSteps      = vm.form.thankyou_steps;
                    vm.thnakyouDownloads  = vm.form.thankyou_downloads;


                    if (vm.form.headline.has_shadow == undefined) {
                        vm.form.headline.has_shadow = 0;
                        vm.form.headline.shadow_x = 0;
                        vm.form.headline.shadow_y = 1;
                        vm.form.headline.shadow_blur = 2;
                        vm.form.headline.shadow_color = 'rgba(0, 0, 0, 0.6)';
                    }

                    if (vm.form && !vm.form.employer_id && vm.thankyouSteps.length >= 2) {
                        vm.thankyouSteps[0].button_link = vm.user.website ? vm.user.website : '';
                        vm.thankyouSteps[2].button_link = vm.user.member_id_url ? vm.user.member_id_url : '';
                    }

                    vm.resetRedactorDefaultValues();

                } else {
                    vm.form = {
                        name: '',
                        category: 'newsletter',
                        has_benefit:  1,
                        thumbnail: '',
                        cover: {
                                    main_img: '',
                                    desk_img: '',
                                    tab_img: '',
                                    mob_img: '',
                                    desk_coordinate: {},
                                    tab_coordinate: {},
                                    mob_coordinate: {},
                                    overlay_color: '',
                                },
                        call_out: {
                                        has_call_out:  1,
                                        text: '',
                                        text_color: '',
                                    },
                        headline: {
                                        text: '',
                                        text_color: '',
                                        has_shadow: 0,
                                        shadow_x: 0,
                                        shadow_y: 1,
                                        shadow_blur: 2,
                                        shadow_color: 'rgba(0, 0, 0, 0.6)',
                                    },
                        sub_headline: {
                                          text: '',
                                          text_color: '',
                                      },
                        benefit: {
                                      benefits: [],
                                      text_color: '#FFFFFF',
                                      icon_color: '#43F4FF',
                                      icon: 'check',
                                  },
                        cta_button: {
                                          text: '',
                                          text_color: '',
                                          bg_color: '',
                                      },
                        coach: {
                                    has_coach:  1,
                                    image: '',
                                    name: '',
                                    credentials: '',
                                    align: 'top-right',
                                },
                        copyright: {
                                        text: '',
                                        text_color: '',
                                    },
                        tags: [],
                        tab: '',
                        popup: {
                                    main_img: '',
                                    desk_img: '',
                                    tab_img: '',
                                    mob_img: '',
                                    desk_coordinate: {},
                                    tab_coordinate: {},
                                    mob_coordinate: {},
                                    headline_text: '',
                                    headline_font_family: '',
                                    headline_font_weight: '',
                                    headline_text_color: '',
                                },
                        optin_submit_button: {
                                                  action_button_text: '',
                                                  action_button_text_color: '',
                                                  action_button_bg_color: '',
                                              },
                        optin_fields: {first_name: 1, last_name: 1, email: 1, phone: 1},
                        gdpr_permission: '',
                        privacy: {
                                      text: '',
                                      icon_color: '',
                                      has_privacy: 1,
                                  },
                        thankyou_type:  1,
                        thankyou_cover: {
                                        main_img: '',
                                        desk_img: '',
                                        tab_img: '',
                                        mob_img: '',
                                        desk_coordinate: {},
                                        tab_coordinate: {},
                                        mob_coordinate: {},
                                        overlay_color: '#15151580',
                                        box_color: '#FFFFFF',
                                    },
                        thankyou_settings: {
                                                redirect_url: '',
                                                headline: '',
                                                headline_text_color: '',
                                                sub_headline: '',
                                                sub_headline_text_color: '',
                                                display_coach:  1,
                                                has_download:  0,
                                                download_headline: '',
                                                has_video:  0,
                                                video_type:  1,
                                                video_link: '',
                                                video_embed: '',
                                                has_cta:  1,
                                                cta_headline: '',
                                                cta_sub_headline: '',
                                                cta_button_text: '',
                                                cta_button_link: '',
                                                cta_button_text_color: '',
                                                cta_button_bg_color: '',
                                                copyright_text: '',
                                                copyright_text_color: '',
                                                copyright_bg_color: '',
                                            },
                        thankyou_steps: [
                                           {
                                                title: '',
                                                info: '',
                                                is_enabled:  1,
                                                has_button:  1,
                                                button_text: '',
                                                button_link: '',
                                                button_text_color: '',
                                                button_bg_color: '',
                                            },
                                        ],
                        has_contact_notification:  1,
                        contact_notification: {
                                                  sending_method:  1,
                                                  subject:  '',
                                                  email_content: '',
                                                  sms_content: '',
                                                  media: [],
                                                  is_signature:  0,
                                                  signature_id:  0,
                                                  show_email_logo:  0,
                                                  email_logo: '',
                                              },
                        has_admin_notification:  1,
                        admin_notification: {
                                                sending_method:  1,
                                                subject:  '',
                                                email_content: '',
                                                sms_content: '',
                                                media: [],
                                                email_recipient: [vm.user.email],
                                                sms_recipient: [vm.user.sms_notification_number],
                                                is_signature:  0,
                                                signature_id:  0,
                                                show_email_logo:  0,
                                                email_logo: '',
                                            },
                        seo_title: '',
                        seo_keyword: '',
                        seo_desc: '',
                        seo_index: 1,
                        seo_share_title: '',
                        seo_share_desc: '',
                        seo_thumb: '',
                        seo_fav: '',
                    };

                    vm.thankyouSteps = [];
                    vm.thnakyouDownloads = [];
                }
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            addStep () {
                const vm = this;

                vm.newsletterDefaultValues.thankyou_steps.push({ title: 'New Title', info: 'New Description' });

                setTimeout(function () {
                    vm.redactorResetWatcher += 1;
                }, 10);

                vm.thankyouSteps.push({
                    title: 'New Title',
                    info: 'New Description',
                    is_enabled: 1,
                    has_button: 0,
                    button_text: '',
                    button_link: '',
                    button_text_color: '#ffffff',
                    button_bg_color: '#2c3e50'
                });
            },

            handleNewsletterCoverCrop (cover) {
                const vm = this;

                vm.form.cover = cover;
            },

            handleNewsletterPopupCrop (popup) {
                const vm = this;

                vm.form.popup.main_img = popup.main_img;
                vm.form.popup.desk_img = popup.desk_img;
                vm.form.popup.tab_img = popup.tab_img;
                vm.form.popup.mob_img = popup.mob_img;
                vm.form.popup.desk_coordinate = popup.desk_coordinate;
                vm.form.popup.tab_coordinate = popup.tab_coordinate;
                vm.form.popup.mob_coordinate = popup.mob_coordinate;
            },

            handleNewsletterThankyouCrop (thankyou) {
                const vm = this;

                vm.form.thankyou_cover.main_img = thankyou.main_img;
                vm.form.thankyou_cover.desk_img = thankyou.desk_img;
                vm.form.thankyou_cover.tab_img = thankyou.tab_img;
                vm.form.thankyou_cover.mob_img = thankyou.mob_img;
                vm.form.thankyou_cover.desk_coordinate = thankyou.desk_coordinate;
                vm.form.thankyou_cover.tab_coordinate = thankyou.tab_coordinate;
                vm.form.thankyou_cover.mob_coordinate = thankyou.mob_coordinate;
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById('gdpr-permission');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.form.gdpr_permission = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            handleContactDefaultEmail () {
                const vm = this;

                vm.form.contact_notification.subject       = vm.getDefaultMessage('email-subject', 'contact-notification');
                vm.form.contact_notification.email_content = vm.getDefaultMessage('email-content', 'contact-notification');

                vm.contactEmail.subject = vm.form.contact_notification.subject;
                vm.contactEmail.email   = vm.form.contact_notification.email_content;
            },

            handleContactDefaultSms () {
                const vm = this;

                vm.form.contact_notification.sms_content = vm.getDefaultMessage('sms-content', 'contact-notification');
            },

            handleAdminDefaultEmail () {
                const vm = this;

                vm.form.admin_notification.subject       = vm.getDefaultMessage('email-subject', 'admin-notification');
                vm.form.admin_notification.email_content = vm.getDefaultMessage('email-content', 'admin-notification');

                vm.adminEmail.subject = vm.form.admin_notification.subject;
                vm.adminEmail.email   = vm.form.admin_notification.email_content;
            },

            handleAdminDefaultSms () {
                const vm = this;

                vm.form.admin_notification.sms_content = vm.getDefaultMessage('sms-content', 'admin-notification');
            },

            handleAdminEmailRecipient (recipient) {
                const vm = this;

                vm.form.admin_notification.email_recipient = recipient;
            },

            handleAdminSMSRecipient (recipient) {
                const vm = this;

                vm.form.admin_notification.sms_recipient = recipient;
            },

            getDefaultMessage (type, section) {
                if (type == 'email-subject' && section == 'contact-notification') {
                    return "You're in {{first_name}}!";
                } else if (type == 'email-content' && section == 'contact-notification') {
                    return '<p>Hi {{first_name}},</p><p>Just a quick note to let you know you are now officially subscribed to our newsletter. <br><br><br>Yay! 😃<br><br>We typically send it out at least once a week so you should get our next issue within 7 days. <br><br> I hope you love it! <br><br> Sincerely,<br> {{company_owner}}<br> {{company_name}}</p>';
                } else if (type == 'sms-content' && section == 'contact-notification') {
                    let sms_message = "Just a quick note to let you know you are now officially subscribed to our newsletter.\r\n";
                        sms_message += "Yay! We typically send it out at least once a week so you should get our next issue within 7 days.\r\n\r\n";
                        sms_message += "I hope you love it!\r\n\r\n";
                        sms_message += "Sincerely,\r\n";
                        sms_message += "{{company_owner}}\r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'admin-notification') {
                    return "New newsletter subscriber";
                } else if (type == 'email-content' && section == 'admin-notification') {
                    return '<p>You have a new newsletter subscriber!<br><br> Source: {{form_name}}<br> First Name: {{first_name}}<br> Last Name: {{last_name}}<br> Email: {{email}}<br> Phone: {{phone}}<br><br> They have also been tagged with {{tag_names}} tag(s) and moved to {{tab_name}} tab.<br><br> Cheers!<br> Thrive Coach </p>';
                } else if (type == 'sms-content' && section == 'admin-notification') {
                    let sms_message = "You have a new newsletter subscriber!\r\n";
                        sms_message += "Source: {{form_name}}\r\n";
                        sms_message += "First Name: {{first_name}}\r\n";
                        sms_message += "Last Name: {{last_name}}\r\n";
                        sms_message += "Email: {{email}}\r\n";
                        sms_message += "Phone: {{phone}}\r\n";
                        sms_message += "They have also been tagged with {{tag_names}} tag(s) and moved to {{tab_name}} tab.\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Thrive Coach";

                    return sms_message;
                }

                return '';
            },

            handleFormSubmit (form, { setFieldError }) {
                const vm = this;
                let params = vm.form;

                params.contact_notification = {
                                                sending_method: vm.form.contact_notification.sending_method,
                                                subject: vm.contactEmail.subject,
                                                email_content: vm.contactEmail.email,
                                                is_signature: vm.contactEmail.is_signature,
                                                signature_id: vm.contactEmail.signature_id,
                                                show_email_logo: vm.contactEmail.show_email_logo,
                                                email_logo: vm.contactEmail.email_logo,
                                                sms_content: vm.form.contact_notification.sms_content,
                                                media: vm.contact_sms_media,
                                            };

                params.admin_notification = {
                                                sending_method: vm.form.admin_notification.sending_method,
                                                subject: vm.adminEmail.subject,
                                                email_content: vm.adminEmail.email,
                                                email_recipient: vm.form.admin_notification.email_recipient,
                                                sms_recipient: vm.form.admin_notification.sms_recipient,
                                                is_signature: vm.adminEmail.is_signature,
                                                signature_id: vm.adminEmail.signature_id,
                                                show_email_logo: vm.adminEmail.show_email_logo,
                                                email_logo: vm.adminEmail.email_logo,
                                                sms_content: vm.form.admin_notification.sms_content,
                                                media: vm.admin_sms_media,
                                            };

                params.setFieldError = setFieldError;

                if (vm.step >= 5) {
                    params.finish = 1;
                }

                vm.updateNewsletter(params).then((newsletter) => {
                    if (newsletter) {
                        vm.form = newsletter;
                        vm.thankyouSteps      = newsletter.thankyou_steps;
                        vm.thnakyouDownloads  = newsletter.thankyou_downloads;

                        vm.resetRedactorDefaultValues();

                        if (vm.step < 5) {
                            vm.step += 1;
                        } else {
                            vm.closeModal();
                        }
                    } else {
                        const hasValidationError = document.querySelector('.has-error');

                        if (hasValidationError) {
                            const container = document.querySelector('.tabs_content');

                            if (container) {
                                container.scrollTop = hasValidationError.scrollTop;
                            }
                        }
                    }
                });
            },

            handleUpdate () {
                const vm = this;
                const form = vm.$refs['newsletter-setting-form'];
                let params = vm.form;

                params.contact_notification = {
                                                sending_method: vm.form.contact_notification.sending_method,
                                                subject: vm.contactEmail.subject,
                                                email_content: vm.contactEmail.email,
                                                is_signature: vm.contactEmail.is_signature,
                                                signature_id: vm.contactEmail.signature_id,
                                                show_email_logo: vm.contactEmail.show_email_logo,
                                                email_logo: vm.contactEmail.email_logo,
                                                sms_content: vm.form.contact_notification.sms_content,
                                                media: vm.contact_sms_media,
                                            };

                params.admin_notification = {
                                                sending_method: vm.form.admin_notification.sending_method,
                                                subject: vm.adminEmail.subject,
                                                email_content: vm.adminEmail.email,
                                                email_recipient: vm.adminEmail.email_recipient,
                                                sms_recipient: vm.form.admin_notification.sms_recipient,
                                                is_signature: vm.adminEmail.is_signature,
                                                signature_id: vm.adminEmail.signature_id,
                                                show_email_logo: vm.adminEmail.show_email_logo,
                                                email_logo: vm.adminEmail.email_logo,
                                                sms_content: vm.form.admin_notification.sms_content,
                                                media: vm.admin_sms_media,
                                            };

                if (form) {
                    params.setFieldError = form.setFieldError;
                }

                vm.updateNewsletter(params).then((newsletter) => {
                    if (newsletter) {
                        vm.form = newsletter;
                        vm.thankyouSteps      = newsletter.thankyou_steps;
                        vm.thnakyouDownloads  = newsletter.thankyou_downloads;
                    } else {
                        const hasValidationError = document.querySelector('.has-error');

                        if (hasValidationError) {
                            const container = document.querySelector('.tabs_content');

                            if (container) {
                                container.scrollTop = hasValidationError.scrollTop;
                            }
                        }
                    }
                });
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            handleDeleteStep (step) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this step`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteStep({ id: step.id }).then((result) => {
                                            if (result) {
                                                vm.handleUpdate();
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleDeleteBenefit (index) {
                const vm      = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this benefit`, 'Delete');

                options.preConfirm = function () {
                                        vm.form.benefit.benefits.splice(index, 1);

                                        return vm.handleUpdate();
                                    };

                Swal.fire(options);
            },

            addBenefit () {
                const vm = this;

                vm.form.benefit.benefits.push('New Feature');
                vm.handleUpdate();
            },

            resetRedactorDefaultValues () {
                const vm = this;

                vm.newsletterDefaultValues = {
                    call_out: vm.form.call_out.text,
                    headline: vm.form.headline.text,
                    sub_headline: vm.form.sub_headline.text,
                    thankyou_headline: vm.form.thankyou_settings.headline,
                    thankyou_sub_headline: vm.form.thankyou_settings.sub_headline,
                    thankyou_steps: []
                };

                vm.form.thankyou_steps.forEach((step, index) => {
                    vm.newsletterDefaultValues.thankyou_steps[index] = { title: step.title, info: step.info };
                });
            },

            openNewsletterCoverCropper () {
                const vm = this;

                vm.coverCropper = true;
            },

            openNewsletterPopupCropper () {
                const vm = this;

                vm.popupCropper = true;
            },

            openNewsletterThankyouCoverCropper () {
                const vm = this;

                vm.thankyouCropper = true;
            },

            resetToDefault (primaryIndex, index = null, key = null) {
                const vm = this;

                const template = JSON.parse(JSON.stringify(vm.newsletterTemplate));

                if (primaryIndex && index && key) {
                    vm.form[primaryIndex][index][key] = template[primaryIndex][index][key];

                    if (primaryIndex == 'thankyou_steps' && key == 'title') {
                        vm.newsletterDefaultValues.thankyou_steps[index].title = template[primaryIndex][index][key] ? template[primaryIndex][index][key] : ' ';
                    }

                    if (primaryIndex == 'thankyou_steps' && key == 'info') {
                        vm.newsletterDefaultValues.thankyou_steps[index].info = template[primaryIndex][index][key] ? template[primaryIndex][index][key] : ' ';
                    }

                } else if (primaryIndex && index) {
                    vm.form[primaryIndex][index] = template[primaryIndex][index];

                    if (primaryIndex == 'call_out' && index == 'text') {
                        vm.newsletterDefaultValues.call_out = template[primaryIndex][index] ? template[primaryIndex][index] : ' ';
                    }

                    if (primaryIndex == 'headline' && index == 'text') {
                        vm.newsletterDefaultValues.headline = template[primaryIndex][index] ? template[primaryIndex][index] : ' ';
                    }

                    if (primaryIndex == 'sub_headline' && index == 'text') {
                        vm.newsletterDefaultValues.sub_headline = template[primaryIndex][index] ? template[primaryIndex][index] : ' ';
                    }

                    if (primaryIndex == 'thankyou_settings' && index == 'headline') {
                        vm.newsletterDefaultValues.thankyou_headline = template[primaryIndex][index] ? template[primaryIndex][index] : ' ';
                    }

                    if (primaryIndex == 'thankyou_settings' && index == 'sub_headline') {
                        vm.newsletterDefaultValues.thankyou_sub_headline = template[primaryIndex][index] ? template[primaryIndex][index] : ' ';
                    }
                } else {
                    vm.form[primaryIndex] = template[primaryIndex] ? template[primaryIndex] : ' ';
                }

                setTimeout(function () {
                    vm.redactorResetWatcher += 1;
                }, 100);
            },

            copyLink () {
                const vm = this;

                copyText(vm.form.thankyou_public_url, undefined, (error, event) => {});
            },
        }
    }
</script>

<style scoped>
    .newsletter-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .optin_list {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -10px;
    }

    .optin_list li {
        padding: 10px;
        width: 50%;
    }

    .optin_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .optin_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .global_setting .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after,
    .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        border-radius: 10px;
    }

    .cell .sms_preview p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview :deep(p) {
        font-size: 11px !important;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .cell .msgFooter :deep(p) {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .msgFooter :deep(p a) {
        text-decoration: none;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px;
    }

    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .range_slider {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0 0;
    }

    .range_slider .range_label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #999;
        margin: 10px 0 0;
    }

    .range_slider span {
        height: 6px;
        background: #2f7eed;
        position: absolute;
        top: 0;
        border-radius: 3px;
    }

    .range_slider span label {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        bottom: 100%;
        padding: 1px 4px;
        background: #2f7eed;
        border-radius: 3px;
        font-size: 9px;
        line-height: 13px;
        color: #fff;
        font-weight: 400;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        display: inline-block;
        margin-bottom: 12px;
    }

    .range_slider span label:after {
        position: absolute;
        content: '';
        border-top: 4px solid #2f7eed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .range_slider .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background: rgba(59, 126, 241, 0.3);
        outline: none;
        margin: 0;
        position: relative;
    }

    .range_slider .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 17px;
        height: 17px;
        border: 2px solid #2f7eed;
        background: #fff;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        z-index: 2;
    }

    .range_slider .slider::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border: 1px solid #2f7eed;
        background: #2f7eed;
        cursor: pointer;
        border-radius: 50%;
    }

    .edit_section {
        display: flex;
        flex-direction: column;
    }

    .step_number {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #e9e9e9;
        margin: -15px -20px 5px -20px;
        padding: 15px 20px;
        background: #fff;
        border-radius: 12px 12px 0 0;
    }

    .edit_section .delete_btn {
        font-size: 13px;
        color: #eb1414;
        cursor: pointer;
    }

    .newsletter_redirect {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .newsletter_redirect .redirect_add {
        width: 330px;
        margin: 0 auto -100px auto;
        height: 40px;
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        background: #fff;
        display: flex;
        align-items: stretch;
        position: relative;
    }

    .newsletter_redirect .redirect_add .prefix_area {
        display: flex;
        align-items: center;
    }

    .newsletter_redirect .redirect_add .prefix_area i {
        padding: 0 10px 0 15px;
        color: #999;
    }

    .newsletter_redirect .redirect_add .prefix_area span {
        font-size: 11px;
        line-height: 20px;
        border-left: 1px solid #d9d9d9;
        padding-left: 8px;
    }

    .newsletter_redirect .redirect_add input[type=text] {
        background: transparent;
        max-width: 227px;
        width: 100%;
        font-size: 11px;
    }

    .newsletter_redirect .redirect_add .btn {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff725e;
        border-radius: 0 20px 20px 0;
        color: #fff;
    }

    .icon_section ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
    }

    .icon_section ul li label {
        width: 42px;
        height: 42px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #999;
        cursor: pointer;
    }

    .icon_section ul li label img {
        max-width: 25px;
        width: auto;
        filter: grayscale(1);
    }

    .icon_section ul li label.active {
        border: 1px solid #8dbcff;
    }

    .icon_section ul li label.active img {
        filter: grayscale(0)
    }

    :deep(.ql-toolbar.ql-snow) {
        border-radius: 6px 6px 0 0;
    }

    :deep(.ql-container.ql-snow) {
        border-radius: 0 0 6px 6px;
    }

    :deep(.ql-container.ql-snow .ql-editor) {
        padding: 15px;
        line-height: 20px;
    }

    :deep(.newsletter-preview-icon) {
        width: 1.4vw;
        height: 1.4vw;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.7vw 0 0;
    }

    :deep(.cell .newsletter .user_img.coach-top-right) {
        top: 60px !important;
        bottom: auto;
    }

    :deep(.cell .newsletter .user_img.coach-bottom-right) {
        bottom: 60px;
    }

    small.url-info {
        font-size: 12px;
    }

    .sms_preview pre {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 20px;
        box-sizing: border-box;
        color: #333;
        margin: 0;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    :deep(.redactor-container .redactor-box ul.redactor-statusbar),
    :deep(.redactor-statusbar:empty) {
        display: none !important;
    }

    :deep(.newsletter_thankyou .nl_header .heading p) {
        font-size: 22px;
        line-height: 30px;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .mtn-30 {
        margin-top: -30px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }

    :deep(.redactor-styles span[style="color: rgb(255, 255, 255);"]) {
        background-color: #C3C3C3;
    }

    .canva-template {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    .canva-flex {
        display: flex;
        flex-direction: row-reverse;
    }

    @media(max-width:767px){
        .info_bar .step_bar2 ul li h6{
            white-space: normal;
            text-align: center;
        }
        .step_bar2 ul{
            padding-bottom: 35px;
        }
    }
    @media(max-width: 600px){
        .color_container .reset-default.mtn-30, .color_container .canva-template.mtn-30{
            margin: -5px 0 10px 0;
        }
    }
</style>
